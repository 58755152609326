/* reset fallback values in modern browsers */
@media screen and ( min-width: 0em ) {

	header, #keyvisual, #subnav, #main, .layout-band, .block_area, #richfooter, footer {
		min-width: 19em; /* 304px */
	}

}

@media screen and ( max-width: 92em ) { /* 1472px */

	.testing #logo { background: blue; }

	// top link
	#top-link {
		a {
			padding: 0.25em 0.5em;
		}
		span {
			text-indent: -9999px;
			font-size: 0.1em;
		}
	}

}


@media screen and ( max-width: 77em ) { /* 1232px */

	.testing #logo { background: green; }

	/* header */
	#top {
		top: 0.8em;
	}

	/* columns */
	.ym-col1 { width: 66.66%; } /* content */
	.ym-col3 { margin: 0 66.66% 0 0; } /* sidebar */


	/* tiles */
	ul.tiles {

		@supports (display: grid) {
			grid-template-columns: repeat(4, 1fr);
		}

		.tile {
			width: 25%;

			@supports (display: grid) {
				width: auto;
			}
		}
		.ad,
		.video-ad {
			width: 50%;

			@supports (display: grid) {
				width: auto;
				grid-column: 3 / 5;
			}
		}
		// clear old
		.tile:nth-of-type(5n+1) {
			clear: none;
		}
		// set new
		.tile:nth-of-type(4n+1) {
			clear: left;
		}
		&.includes-add {
			// clear default
			.tile:nth-of-type(4n+1) {
				clear: none;
			}
			// clear old
			.product:nth-of-type(5n-1) {
				clear: none;
			}
			// set new
			.product:nth-of-type(4n-1)
			{
				clear: left;
			}
		}
		&.large {
			grid-template-columns: repeat(3, 1fr);

			.tile {
				width: 33.33%;

				@supports (display: grid) {
					width: auto;
				}

				// clear default
				.tile:nth-of-type(4n+1) {
					clear: none;
				}
				// clear old
				&:nth-of-type(4n+1) {
					clear: none;
				}
				// set new
				&:nth-of-type(3n+1) {
					clear: left;
				}
			}
		}
	}

}

@media screen and ( max-width: 65em ) { /* 1040px */

	.testing #logo { background: lime; }

	#top {
		.call-us {
			display: block;
			margin: 0 0 0.2rem 0;
		}
		#searchbox {
			display: block;
		}
	}

	/* case studies */
	.casestudypage .maincontent .ym-wbox {
	    padding-right: 20%;
	}

}

@media screen and ( max-width: 60em ) { /* 960px */

	.testing #logo { background: yellow; }

	/* keyvisual */
	.keyvisual .content .text-wrapper .text {
		font-size: 170%;
	}

	/* content */
	h1 { font-size: 250%; }
	h2 { font-size: 200%; }
	h3 { font-size: 150%; }
	h4 { font-size: 112.5%; }
	h5 { font-size: 100%; }
	h6 { font-size: 100%; }

	/* bands */
	.layout-band {
		.homepage & h2,
		.landingpage & h2 {
			font-size: 250%;
		}
	}

	/* tiles */
	ul.tiles {
		@supports (display: grid) {
			grid-template-columns: repeat(3, 1fr);
		}

		.tile {
			width: 33.33%;

			@supports (display: grid) {
				width: auto;
			}
		}
		.ad,
		.video-ad {
			width: 66.66%;

			@supports (display: grid) {
				width: auto;
				grid-column: 2 / 4;
			}
		}
		// clear old
		.tile:nth-of-type(4n+1) {
			clear: none;
		}
		// set new
		.tile:nth-of-type(3n+1) {
			clear: left;
		}
		&.includes-add {
			// clear default
			.tile:nth-of-type(3n+1) {
				clear: none;
			}
			// clear old
			.product:nth-of-type(4n-1) {
				clear: none;
			}
			// set new
			.product:nth-of-type(3n-1)
			{
				clear: left;
			}
		}
	}

	.contactpage {

		.contact-columns {
			.contact-column-one { /* map */
				float: none;
				width: 100%;

				.contact-column-inner {
					padding-left: 0;
				}
			}
			.contact-column-two { /* form */
				 margin: 0;

				.contact-column-inner {
					padding-right: 0;
				}
			}
		}
	}

	/* rich footer */
	#richfooter .ym-wbox {

		.wrapper {
			flex-wrap: wrap;

			.box {
				&.box-social {
					width: 100%;
					text-align: center;
					padding: 0;
					padding-left: 1em;

					#language-page-selector {
						display: inline-block;
						margin-bottom: 1em;
						align-self: center;
					}

					.social-links {
						.js & {
							display: block;
							text-align: center;
						}
					}
				}
				&.box-nav {
					&.last {
						padding-right: 1em;
					}
				}
			}
		}
	}

}

@media screen and ( max-width: 54em ) { /* 864px */

	.keyvisual .flexslider .content .text-wrapper {
	    left: 4em;
	}
}

@media screen and ( max-width: 53em ) { /* 848px */

	.testing #logo { background: orange; }

	/* header */
	header .ym-wbox {
    	padding: 4em 1em 1em;
	}

	/* keyvisual */
	.keyvisual .content .text-wrapper {
		bottom: 2.5em;
	}

	/* linearization for grid module */
	.linearize-level-1,
	.linearize-level-1 > [class*="ym-g"] {
		display: block;
		float: none;
		padding: 0;
		margin: 0;
		width: 100% !important;
	}
	/* reset defined gutter values */
	.linearize-level-1 > [class*="ym-g"] > [class*="ym-gbox"] {
		overflow: hidden; /* optional for containing floats */
		padding: 0;
		margin: 2em 0 0 0;
	}
	.linearize-level-1 > [class*="ym-g"] > [class*="ym-gbox-left"] {
		margin: 0;
	}

	/* site map */
	ul.sitemap > li {
		width: 46%;
		&:first-child {
		    margin-left: 27%;
		    margin-right: 27%;
		}
	}

		/* tiles */
	ul.tiles {
		&.large {
			grid-template-columns: repeat(2, 1fr);

			.tile {
				width: 50%;

				@supports (display: grid) {
					width: auto;
				}

				// clear default
				.tile:nth-of-type(3n+1) {
					clear: none;
				}
				// clear old
				&:nth-of-type(3n+1) {
					clear: none;
				}
				// set new
				&:nth-of-type(2n+1) {
					clear: left;
				}
			}
		}
	}

	/* case studies */
	.casestudypage {
		.maincontent {
			.ym-wbox {
			    padding-right: 0;

			    article {
			    	padding-right: 1em;
			    	padding-bottom: 1em;
			    }
			}
			.image {
				position: relative;
				width: 100%;
				height: auto;

				img {
					max-width: 100%;
					min-height: auto;
					min-width: auto;
					position: relative;
					top: none;
					right: none;
				}
			}
		}
		.layout-band.callout .ym-wbox .callout {
			width: 80%;
			right: 2%;
		}
		.layout-band.documents .ym-wbox .ym-gbox-right {
			margin: 0;
		}
	}

	/* rich footer */
	#richfooter .ym-wbox .wrapper {
		flex-wrap: wrap;

		.box {
			&.box-contact {
				width: 100%;
				padding-right: 0;

				.anyflexbox & {
					width: calc(100% - 130px);
					flex: none;
				}

			}
			&.box-nav {
				border: 0;
				margin: 0;
				width: 25%;

				.anyflexbox & {
					padding: 2em 0 0 2em;
				}
				&.last {
					padding-right: 0;
				}
			}
			&.box-social {
				padding-top: 2em;
				padding-left: 1em;
			}
		}
	}

	/* footer */
	footer {
		.ym-contain-dt {
			position: relative;
		}
		.copyright { float: none; }
		#nav-footer {
			float: none;
			margin-top: 0.5em;

			li:first-child { border: 0; margin-left: 0; padding-left: 0; }
		}
		.reference { position: absolute; right: 0; top: 0; }
	}

}


@media screen and ( max-width: 48em ) { /* 768px */

	.testing #logo { background: red; }

	/* keyvisual */
	.keyvisual .content .mask {
    	display: none;
	}
	.keyvisual .content .text-wrapper .text {
	    font-size: 150%;
	}

	/* tiles */
	ul.tiles {

		@supports (display: grid) {
			grid-template-columns: repeat(2, 1fr);
		}

		.tile {
			width: 50%;

			@supports (display: grid) {
				width: auto;
			}
		}
		.ad,
		.video-ad {
			width: 100%;

			@supports (display: grid) {
				width: auto;
				grid-column: 1 / 3;
			}
		}
		// clear old
		.tile:nth-of-type(3n+1) {
			clear: none;
		}
		// set new
		.tile:nth-of-type(2n+1) {
			clear: left;
		}
		&.includes-add {
			// clear default
			.tile:nth-of-type(2n+1) {
				clear: none;
			}
			// clear old
			.product:nth-of-type(3n-1) {
				clear: none;
			}
			// set new
			.product:nth-of-type(2n-1)
			{
				clear: left;
			}
		}
	}

	/* case studies */
	.casestudypage {
		.maincontent {
			.padded {
				padding: 2em 2em 0 2em;

			    h2:first-child {
			    	position: relative;
					top: auto;
					left: auto;
					width: 100%;
			    }
			    p:first-of-type {
			    	margin: 1.2em 0 0;
			    }
			}
		}
	}


	/* subcolumns */
	.ym-col1 { float: none; width: 100%; }
	.ym-col3 { margin: 0; }
	.ym-col3 .ym-cbox { margin: 0; }
	.listboxes { padding-top: 0; }

}

@media screen and ( max-width: 40em ) { /* 640px */

	.testing #logo { background: purple; }

	/* header */
	header .ym-wbox {
    	padding: 0.8em 1em 1em 4.5em;
	}
	#top {
		top: 0.5em;
	}
	#top .LuceneSearchForm input.text {
	    width: 8em;
	}

	/* key visual */
	.keyvisual .flexslider .content .text-wrapper {
		bottom: 2em;
		.text {
			font-size: 120%;
		}
	}

	/* content */
	h1 { font-size: 225%; }
	h2 { font-size: 200%; }
	h3 { font-size: 150%; }
	h4 { font-size: 112.5%; }
	h5 { font-size: 100%; }
	h6 { font-size: 100%; }

	/* bands */
	.layout-band {
		.homepage & h2 {
			font-size: 225%;
		}
	}

	/* product filter */
	.js {
		.product-filter {
			.filter-group {
				a,
				a:visited {
					float: none;
					display: none;
				}
				a.current,
				a.current:visited {
					display: block;
				}
				&.open {
					a,
					a:visited {
						display: block;
					}

				}
			}
		}
	}

	/* case studies */
	.casestudypage {
		.maincontent {
			.padded {
			    h2:first-child {
			    	font-size: 150%;
			    }
			}
		}
		.layout-band.callout .ym-wbox .callout {
			font-size: 150%;
		}
		.layout-band.documents .ym-wbox .ym-gbox-right ul {
			column-count: 1;
			column-gap: 0;
		}
	}

	/* site map */
	ul.sitemap > li {
		width: 100%;
		margin-left: 0;
		margin-right: 0;

		&:first-child {
		    margin-left: 0;
		    margin-right: 0;
		}
	}

	/* rich footer */
	#richfooter .ym-wbox .wrapper {
		justify-content: flex-start;

		.box {
			&.box-nav {
				width: 33.33%;
				flex: none;
			}
		}
	}

}

@media screen and ( max-width: 35em ) { /* 560px */

	/* key visual */
	.keyvisual .flexslider .content .text-wrapper {
		bottom: 1.5em;
		max-width: 80%;
		left: 10%;
		.text {
			padding-right: .5em;
		}
		.border {
			display: none;
		}
	}

	/* case study filter */
	.casestudy-filter {
		label {
			float: none;
			width: auto;
		}
		.filter-group {
			margin-left: 0;
		}
	}
}

@media screen and ( max-width: 30em ) { /* 480px */

	.testing #logo { background: blue; }

	/* header */
	#top { display: none; }

	/* tiles */
	ul.tiles {
		@supports (display: grid) {
			grid-template-columns: 1fr;
		}

		.tile {
			width: 100%;

			@supports (display: grid) {
				width: auto;
			}

		}
		.ad,
		.video-ad {
			width: 100%;

			@supports (display: grid) {
				width: auto;
				grid-column: 1 / 2;
			}
		}

		// clear old
		.tile:nth-of-type(2n+1) {
			clear: none;
		}
		// set new
		.tile {
			clear: left;
		}
		&.large {
			grid-template-columns: 1fr;

			.tile {
				width: 100%;
			}
		}
	}

	/* case studies */
	.casestudypage {
		.layout-band.callout .ym-wbox .callout {
			font-size: 120%;
		}
	}


	/* rich footer */
	#richfooter .ym-wbox .wrapper {
		.box {
			&.box-nav {
				width: 50%;
			}
		}
	}

	/* footer */
	footer .reference { position: relative; top: auto; right: auto; float: none; margin-top: 0.72em; }

}

@media screen and ( max-width: 25em ) { /* 400px */

	.testing #logo { background: green; }

	/* header */
	header .ym-wbox {
	    padding: 1em 1em 1em 4.5em;
	}
	#logo {
		font-size: 2.5em;
	}

	/* forms */
	span.readonly, input.text, textarea, select { width: 90%; }

	/* rich footer */
	#richfooter .ym-wbox .wrapper {
		.box {
			&.box-nav {
				width: 100%;
			}
		}
	}

}
