form.dropdown-filter {
	margin: 2em 0 2em;
	
	.action-wrapper {
		margin-top: 1em;
	}
	
	@media screen and ( min-width: 53em ) { /* 848px */
	
		margin: 3em 0 2em;
	
		.form-wrapper {
			
			.anyflexbox & {
				display: flex;
				justify-content: space-between;
			}
		}
		
		.field-wrapper {
			
			.anyflexbox & {
				display: flex;
				flex: 1 1 auto;
			}
			
			& > .field {
			
				label {
					font-size: 0.8rem;
					argin: 0 0 .2rem 0;
				}
			
				.anyflexbox & {
					margin: 0 1em 0 0;
					flex: 1;
				}
			}
		}
		
		.action-wrapper {
		
			.anyflexbox & {
				display: flex;
				align-self: flex-end;
				flex: 0 0 auto;
				margin-top: 0;
			}
			
			a.button, 
			input.action {
				border-radius: 0.2em;
				
				.anyflexbox & {
					padding: 1rem 1.5rem;
					margin: 0 1em 0 0;
					
					&:last-child {
						margin: 0;
					}
				}
			}
		}
		
	}
	
	input.text, select {
	    display: block;
		border-radius: 0.2em;
	    border: {
	        width: 1px;
	        style: solid;
	        color: $color-box-lighter;
	    }
	    padding: 1rem;
		color: $color-box-darker;
		font-size: 1rem;
		line-height: 1.2em;
		width: 95%;
		max-width: 480px;
		font-family: $font-stack-body;
		
		@media screen and ( min-width: 53em ) { /* 848px */
			.anyflexbox & {
				width: 100%;
				max-width: 100%;
			}
		}
		
		.fonts-loaded & {
			font-family: $font-stack-body-full;
		}
		
		&:focus {
	        border-color: $color-body;
	        color: $color-body;
	        background-color: $color-background-light;
		}
	}
	
	select {
	    appearance: none;
        background: $color-background-light url(/themes/yamltheme/client/dist/images/dropdown-arrow.svg) no-repeat right 1em center;
        background-size: 1em;
        padding-right: 3.5rem;
	
	    // https://stackoverflow.com/questions/19451183/cannot-remove-outline-dotted-border-from-firefox-select-drop-down
	    &:-moz-focusring {
	        color: transparent;
	        text-shadow: 0 0 0 #000;
	    }
		// https://stackoverflow.com/questions/20163079/remove-select-arrow-on-ie
		&::-ms-expand {
			display: none;
		}
	
	    option:not(:checked) {
	        color: $color-body;
	        background: $color-background-light;
	    }
	    
	}
	
	select::-ms-expand {
	    visibility: hidden;
	}
	
	#language {
		.middleColumn {
			width: 95%;
			max-width: 480px;
			position: relative;
			
			&:before {
				content: " ";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				background: $color-pyrotek-red;
				height: 100%;
				width: 3em;
				border-radius: 0 2em 2em 0;
				z-index: 0;
			}
			
			select {
		    	background-image: url(/themes/yamltheme/client/dist/images/languages.svg);
		    	background-size: 1.5rem 1.5rem;
		    	background-position-x: right 0.8rem;
		    	background-color: transparent;
				border-radius: 1.7em;
				width: 100%;
				position: relative;
				z-index: 1;			
			}
		}
	}
	
	.field.js-suggest {
		position: relative;

		.autocompleter {
		    width: 100%;
		    display: none;
		    z-index: 1000;
		}
		
		.autocompleter-show {
		    display: block;
		}
		
		.autocompleter,
		.autocompleter-hint {
			position: absolute;
			background-color: white;
			
			border-radius: 0.2em;
		}
		
		.autocompleter-list {
			border: 1px solid $color-body;
			list-style: none;
			margin: 0;
			padding: 0;
		}
		
		.autocompleter-item {
		    cursor: pointer;
		    padding: 0.5em;
			margin: 0;
		}
		
		.autocompleter-item:hover {
		    background-color: $color-pyrotek-red;
		    color: #fff;
		}
		
		.autocompleter-item-selected {
		    /* Selected State */
		}
		
		.autocompleter-item strong {
		    /* Highlight Matches */
		}
		
		.autocompleter-hint {
		    width: 100%;
		    display: none;
		
		    /** Need absolute position over input text */
		}
		
		.autocompleter-hint-show {
		    display: block;
		}
		
		.autocompleter-hint span {
		    color: transparent;
		}
	}
	
}

form.js-dropdown-filter {
	@media screen and ( min-width: 53em ) { /* 848px */
		.field-wrapper {
			& > .field {
				.anyflexbox.js &:last-child {
					margin-right: 0;
				}
			}
		}
	}	
	
	.action-wrapper {
		.js & {
			display: none;
		}
	}
	
}

