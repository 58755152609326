$font-stack-header:			Arial, Helvetica, sans-serif;
$font-stack-header-full:	"Open Sans", Arial, Helvetica, sans-serif;
$font-stack-body:			Arial, Helvetica, sans-serif;
$font-stack-body-full:		"Open Sans", Arial, Helvetica, sans-serif;
$font-stack-pre:			Consolas, "Lucida Console", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier;
$font-stack-quote:			Georgia, "Times New Roman", Times, serif;

$color-pyrotek-red:			#EE3426;
$color-pyrotek-red-light:	lighten($color-pyrotek-red, 8%);
$color-pyrotek-red-dark:	darken($color-pyrotek-red, 10%);

$color-body:				#77776F; /* 119 119 111 */
$color-body-dark:			#000000;
$color-header:				#58574E; /* 88 87 78 */
$color-header-light:		#A8A89F; /* 168 168 159 */

$color-nav:					#7F7E73; /* 127 126 115 */
$color-nav-lang:			#7F7E73; /* 127 126 115 */
$color-nav-lang-border:		#BDBDBA;

$color-box:					#d2d2cd;
$color-box-light:			lighten($color-box, 8%);
$color-box-lighter:			lighten($color-box, 11%);
$color-box-very-light:		lighten($color-box, 15%);
$color-box-dark:			darken($color-box, 10%);
$color-box-darker:			darken($color-box, 15%);
$color-box-very-dark:		darken($color-box, 30%);

$color-background-light:	#ffffff;
$color-background-dark:		#333333;


@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($top)}', endColorstr='#{ie-hex-str($bottom)}',GradientType=0); /* IE6-9 */
}
