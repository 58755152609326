.cookiebanner {
	display: none;
}
.show-cookiebanner {
	footer {
		padding-bottom: 24em;
		
		@media screen and ( min-width: 20em ) {
			padding-bottom: 18em;
		}
		@media screen and ( min-width: 25em ) {
			padding-bottom: 14em;
		}
		@media screen and ( min-width: 35em ) {
			padding-bottom: 12em;
		}
		@media screen and ( min-width: 45em ) {
			padding-bottom: 10em;
		}
		@media screen and ( min-width: 64em ) {
			padding-bottom: 6em;
		}
	}
	.cookiebanner {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		color: #fff;
		background: $color-background-dark; 
		font-size: 0.875em;
		line-height: 1.2em;
		padding: 2em 7em 2em 2em;
		z-index: 1000;
		
		@media screen and ( min-width: 92em ) {
			padding: 2em 10em;
		}
		
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		
		@media screen and ( min-width: 64em ) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		
		.content {
			float: left;
			
			:first-child {
				margin-top: 0;
			}
		}
		
		a {
			color: #fff;
			text-decoration: underline;
		}
		a.button {
			float: right;
			flex: 0 0 auto;
			margin: 1em 0 0 0;
			text-decoration: none;
			
			@media screen and ( min-width: 64em ) {
				margin: 0 0 0 3em;
			}
		}
	}	
}
