ul.pager {
	list-style-type: none;
	margin: 1em 0 0 0;
	padding: 0;
	overflow: hidden; 
	
	li { 
		margin: 0;
		padding: 0;
		float: left; 
	}
	
	a,
	strong {
		display: inline-block;
		border: 1px solid $color-pyrotek-red;
		border-radius: 50%;
		color: $color-pyrotek-red;
		font-weight: 300;
		margin: 0 0.5em 0 0;
		
		span {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
			width: 3em;
			height: 3em;
		}
	}

	a {
		&:focus,
		&:hover,
		&:active { 
			text-decoration: none; 
			outline: 0 none;
			background: $color-box-light;
		}
		.icon {
			margin-top: 0.4em;
		}
	}
	strong {
		border-color: $color-box;
		color: $color-body;
	}
}