.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: text-bottom;
  margin-bottom: 0.12em;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-logo {
  width: 3.5947265625em;
}

.icon-symbol {
  width: 1.0234375em;
}

.icon-menu {
  width: 0.8571436069905758em;
}

.icon-close {
  width: 0.7857149094343185em;
}

.icon-arrow-up {
  width: 0.9999999701976776em;
}

.icon-arrow-down {
  width: 0.9999999850988388em;
}

.icon-arrow-left {
  width: 0.7500009983778em;
}

.icon-arrow-right {
  width: 0.6785712540149689em;
}

.icon-caret-left {
  width: 0.3928574174642563em;
}

.icon-caret-right {
  width: 0.32142969965934753em;
}

.icon-cart {
  width: 0.9285732470452785em;
}

.icon-cog {
  width: 0.8571446221321821em;
}

.icon-search {
  width: 0.9285706554849185em;
}

.icon-home {
  width: 0.928572153672576em;
}

.icon-print {
  width: 0.9285733308643103em;
}

.icon-info {
  width: 0.8571425850192682em;
}

.icon-download-circle {
  width: 0.8571425080299377em;
}

.icon-download {
  width: 0.9285712763667107em;
}

.icon-comments {
  width: 1.0000030547380447em;
}

.icon-news {
  width: 1.1428594440221786em;
}

.icon-email {
  width: 1.000000972300768em;
}

.icon-fax {
  width: 1.0000000037252903em;
}

.icon-skype {
  width: 0.8571436032652855em;
}

.icon-facebook {
  width: 0.5881709158420563em;
}

.icon-twitter {
  width: 0.9285703301429749em;
}

.icon-linkedin {
  width: 0.8571429451305903em;
}

.icon-google {
  width: 0.8398430943489075em;
}

.icon-pinterest {
  width: 0.7142868041992188em;
}

.icon-youtube {
  width: 1.0000000305008143em;
}

.icon-rss {
  width: 0.7864560410380363em;
}

.icon-contact {
  width: 0.9955341517925262em;
}
