@media all {
	
	#mainnav { 
		display: table-cell;
		vertical-align: bottom;
	}
	
	#mainnav .ym-hlist {
		padding: 0;
		margin: 0 0 0.2em 0;
		float: right;
		
		ul {
			margin: 0;
			padding: 0;
			/* (en|de) Bugfix:IE - Doubled Float Margin Bug */
			display: inline;
			width: 100%;
		}
		
		li {
			/* (en|de) Bugfix:IE - Doubled Float Margin Bug */
			display:inline;
			float:left; /* LTR */
			font-weight: 400;
			font-size:1em;
			text-transform: lowercase;
			line-height:1.2em;
			list-style-type:none;
			margin: 0;
			padding: 0;
		}
				
		li a {
			background: #fff;
			color: $color-nav;
			display: block;
			padding: 0.4em 1em;
			margin: 0 0.5em 0 0;
			text-decoration: none;
			width: auto;
		}
		li.last a { margin: 0; }
		li a:focus,
		li a:hover,
		li a:active,
		li a.current,
		li a.section {
			text-decoration: none;
			outline: 0 none;
			color: $color-body-dark;
			background: $color-box-light;
		}
		li a.children { display: none; }
		
		/* level 2 */
		li ul {
			display: none;
		}
	
	}
}


@media screen and ( min-width: 75em ) { /* 1200px */
	header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 999;
		
		.ym-wbox {
			transition: padding 0.2s;
		}
		#top {
			transition: top 0.2s;
		}
	}
	#inner-wrap {
		margin-top: 10em;
	}
	.scrolled {
		header {
			border-bottom: 1px solid #000;
			
			.ym-wbox {
				padding: 2.5em 1em 0.5em;
			}
			#top {
				top: 1em;
			}
		}
	}
	
	#mainnav .ym-hlist {
		/* level 2 */
		& > ul {
			& > li {
				position: relative; 
				
				& > ul {
					position: absolute;
					overflow: hidden; 
					padding: 0;
					margin: 0;
					z-index: 999;
					width: auto;
					min-width: 230px;
					max-width: 320px;
					zoom: 1;
					background: none;
					max-height: 0;
					transition: max-height 0.3s;
					display: block;
					
					& > li {
						display: block;
						width: 100%;
						border-bottom: 1px solid #e4e4e4;
						
						&:first-child {
							margin-top: 0.5em;
							border-top: 1px solid #e4e4e4;
						}
						
						a {
							margin: 0;
						}
					}
				}
				
				&:focus > ul,
				&:hover > ul,
				&:active > ul {
					max-height: 100em;
				}
			}
		}
		
	}
}