.layout-band {
	.ym-gbox-left p:first-child,
	.ym-gbox-right p:first-child,
	h2:first-child { 
		margin-top: 0; 
		margin-bottom: 0.5em;
	}
	
	&.padded {
		.ym-wbox {
			padding-left: 20%;
		}
	}
	
	.centered h2 {
		text-align: center; 
	}
	.homepage & h2,
	.landingpage & h2 {
		font-size: 300%;
	}
	.homepage & .typography > h2,
	.homepage & .block > h2,
	.homepage & .block .cta,
	.landingpage & .typography > h2,
	.landingpage & .block > h2,
	.landingpage & .block .cta {
		text-align: center; 
	}
	
	.homepage & .typography .video,
	.landingpage & .typography .video {
		margin-top: 1.6em;
	}
	
	.image .captionImage {
		margin: 0.3em 0 0;
	}
	
	a.buttonlink {
		&,
		&:visited {
			color: #fff;
			@include linearGradient($color-pyrotek-red, $color-pyrotek-red-dark);
			font-size: 1em;
			text-decoration:none;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			display: inline-block;
			padding: 0.6em 1em;
			margin: 0 0.5em 0 0;
			border-radius: 2em;
		}
		&.current,
		&:hover,
		&:focus,
		&:active  {
			text-decoration: none;
			outline: 0 none;
			@include linearGradient($color-pyrotek-red-dark, $color-pyrotek-red);
		}
		&.fullwidth {
			width: 100%;
			margin: 0;
		}
	}
	ul.buttons {
		width:100%;
		overflow:hidden;
		margin: 0.3em 0 0 0;
		list-style-type: none;
				
		li {
			margin: 0 0 1em 0;
			padding: 0;
		}
	}
	
	.cta {
		margin-top: 1em;
		 
		a {
			&,
			&:visited {
				color: $color-pyrotek-red;
				background: transparent;
				border: 1px solid $color-pyrotek-red;
				font-size: 1em;
				text-decoration:none;
				text-transform: uppercase;
				font-weight: 400;
				text-align: center;
				display: inline-block;
				padding: 0.6em 2.5em;
				margin: 0;
			}
			&.current,
			&:hover,
			&:focus,
			&:active  {
				text-decoration: none;
				outline: 0 none;
				color: #fff;
				background: $color-pyrotek-red;
			}		
		}
	}
	.centered .cta {
		text-align: center;
	}

}