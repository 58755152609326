.block_area {
	.block_wrapper {
		padding: 3em 0;
	
		.ym-wbox {
			padding-bottom: 0;
			padding-top: 0;
		}
		
		.keyvisual {
			margin-bottom: 2em;
		}
	}
}
.block_area_withincontent {
	.block_wrapper {
		&:nth-child(2n+1) {
			background: $color-box-lighter;
			
			.keyvisual {
				.content .mask {
					fill: $color-box-lighter;
				}
				.dots {
					background: $color-box-lighter;
				}
			}
		}
	}
}
.block_area_aftercontent {
	.block_wrapper {
		&.zebra:nth-child(2n+2) {
			background: $color-box-lighter;
			
			.keyvisual {
				.content .mask {
					fill: $color-box-lighter;
				}
				.dots {
					background: $color-box-lighter;
				}
			}
		}
		&.zebra-inverted:nth-child(2n+1) {
			background: $color-box-lighter;
			
			.keyvisual {
				.content .mask {
					fill: $color-box-lighter;
				}
				.dots {
					background: $color-box-lighter;
				}
			}
		}
	}
}
