.LuceneSearchForm {
	display: inline-block;
	margin-top: 2em;
	overflow: hidden;
	border-radius: .2em;
	border: 1px solid $color-box;
	
	#top & {
		margin: 0;
		font-size: 0.85em
	}
	
	label {
	    position: absolute;
	    top: -32768px;
	    left: -32768px;
	}
	.field.text { 
		margin: 0; 
		float: left; 
	}
	input.text {
		border: 0;
		line-height: 1.2em;
		padding: 0.4em 0.8em;
		
		#top & {
			width: auto;
		}
	}
	input.action { 
		float: right; 
		padding: 0;
		height: 2em;
		width: 2.2em;
		text-indent: -32768px;
		border: 0;
		border-left: 1px solid $color-box;
		background-color: transparent;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2230%22%20height%3D%2232%22%20viewBox%3D%220%200%2030%2032%22%3E%0A%3Cpath%20fill%3D%22%2377776F%22%20d%3D%22M20.571%2014.857q0-3.304-2.348-5.652t-5.652-2.348-5.652%202.348-2.348%205.652%202.348%205.652%205.652%202.348%205.652-2.348%202.348-5.652zM29.714%2029.714q0%200.929-0.679%201.607t-1.607%200.679q-0.964%200-1.607-0.679l-6.125-6.107q-3.196%202.214-7.125%202.214-2.554%200-4.884-0.991t-4.018-2.679-2.679-4.018-0.991-4.884%200.991-4.884%202.679-4.018%204.018-2.679%204.884-0.991%204.884%200.991%204.018%202.679%202.679%204.018%200.991%204.884q0%203.929-2.214%207.125l6.125%206.125q0.661%200.661%200.661%201.607z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 50%;
	}
}

#searchresults {
	ul.listing {
		list-style-type: none;
		margin: 0;
		padding: 1em 0 0 0;
		
		li {
			margin: 1em 0 0 0;
			padding: 0 0 1em 0;
			
			h2 { 
				margin: 0;
			}
			p {
				margin: 0;
			}
		}
	}
	.pagination {
		overflow: hidden;
		margin-top: 1em; 
		display: table;
		width: 100%;
	
		p { 
			margin: 0; 
			display: table-cell;
			vertical-align: middle;
		}
	
		&.top {
			padding-bottom: 1em;
			border-bottom: 1px solid $color-box;
		}
		&.bottom {
			padding-top: 1em;
			border-top: 1px solid $color-box;
		}
		
		.pager { 
			display: table-cell;
			float: right; 
			margin: 0;
		}
	}
}