@media all {
	
	#subnav { 
		 margin: 0 0 0.5em 0;
		 background: $color-box-very-light;
		 
		 .ym-wbox {
		 	padding: 0.5em 1em;
		 }
		
		.ym-hlist {
			
			ul {
				width:100%;
				overflow:hidden;
				margin: 0;
				list-style-type: none;
				
				li {
					float:left; /* LTR */
					margin: 0;
					
					a,
					a:visited {
						color: $color-body;
						font-size: 1em;
						text-decoration:none;
						text-transform: lowercase;
						display:block;
						padding: 0.4em 1em;
						margin: 0 0.5em 0 0;
					}
					a.current,
					a:hover,
					a:focus,
					a:active  {
						text-decoration: none;
						outline: 0 none;
						color: $color-body-dark;
						background: $color-box-light;
					}
				}
			}
		}
		
	}

}