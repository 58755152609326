
html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body { overflow-y:scroll; }

body { 
	text-align: left; 
	/* (en) Prevent auto-scaling of text in mobile webkit browsers */
	-webkit-text-size-adjust:100%;
	background: $color-background-light;
}

/* Layout Module Configuration */
.ym-wrapper {
	max-width: 77em; /* 1200px */
	margin: 0 auto;
}

.ym-wbox {
	padding: 2em 1em;
	
	@media screen and ( min-width: 53em ) { /* 848px */
		padding: 3em 1em;
	}
}

header, #keyvisual, #subnav, #main, .layout-band, .block_area, #richfooter, footer { min-width: 54em; } /* 760px */

.ym-g15 { width: 15%; }
.ym-g85 { width: 15%; }
.ym-g18 { width: 18.75%; }
.ym-g30 { width: 30%; }
.ym-g70 { width: 70%; }

.ym-gbox-padded {
	padding-top: 4.9em;
}

#oldie { font-size: 1.5em; background: #fda62f; text-align: center; margin: 0; padding: 1em; }

/*-------------------------------------------------------------------------*/

.ym-gbox { padding-left: 2em; padding-right: 2em; margin-left: 0; margin-right: 0; }
.ym-gbox-left { padding-left: 0; padding-right: 2em; margin-left: 0; margin-right: 0; }
.ym-gbox-right { padding-left: 2em; padding-right: 0; margin-left: 0; margin-right: 0; }

/*-------------------------------------------------------------------------*/

header { 
	background: #fff;
}
header .ym-wbox { 
	padding: 5em 1em 2em; 
	position: relative;
}
header .wrapper {
	display: table; 
	width: 100%;
}

#logo { 
	margin: 0; 
	padding: 0;
	display: table-cell;
	font-size: 3em; 
	color: $color-pyrotek-red;
}
#logo svg { display: block; margin: 0; }
#logo:hover { text-decoration: none; }

#top { 
	position: absolute;
	top: 2em;
	right: 1em; 
	text-align: right;
	
	.call-us {
		display: inline-block;
		vertical-align: top; 
		font-size: 1.3125rem;
		font-weight: 600;
		line-height: 1.2em;
		margin-right: 1rem;
		
		.icon {
			margin-right: 0.1em;
		}
	}
	#searchbox {
		display: inline-block;
		vertical-align: top;
	}
}

/*-------------------------------------------------------------------------*/

#breadcrumbs { margin: 0 0 1.5em 0; padding: 0; overflow: hidden; }
#breadcrumbs li { float: left; list-style: none outside none; line-height: 1.2em; 
	padding: 0; margin: 0; background-image: none; color: #333; font-size: 0.875em; }
#breadcrumbs li:before { content: ">"; margin: 0 0.5em; font-size: 1.2em; line-height: 1em; vertical-align: -0.05em; }
#breadcrumbs li:first-child:before { content: ""; margin: 0; }
#breadcrumbs li a { color: #767676; }
#breadcrumbs li a:focus,
#breadcrumbs li a:hover,
#breadcrumbs li a:active { text-decoration: none; outline: 0 none; color: #0e56a8; }

/*-------------------------------------------------------------------------*/

#richfooter { 
	background: $color-pyrotek-red url("/themes/yamltheme/client/dist/images/background-rich-footer.jpg") no-repeat;
	background-size: cover; 
	background-position: center;
	color: #fff;
	
	.ym-wbox {
		padding: 4em 1em;
		overflow: hidden;
		.anyflexbox & {
			padding: 4em 1em 4em 0;
		}
	} 
	
	.wrapper {
		width: 100%;
		display: table;
		
		.anyflexbox & {
			display: flex;
			justify-content: space-between;
		}
		
		.box {
			padding: 0 1em 2em 0;
			margin: 0 0 1.5em 0;
			float: left;
			
			.anyflexbox & {
				padding: 2em 1em 2em 0;
				flex: 0 1 auto;
				display: inline-block;
			}
			
			&.box-contact,
			&.box-nav {
				width: 17.5%;
				
				.anyflexbox & {
					flex: 2;
					width: auto;
					border-bottom: 1px solid #fff;
				}
			}
			&.box-contact {
				padding-right: 3em;
				width: 30%;
				
				.anyflexbox & {
					width: auto;
					flex: 3;
				}
			}
			&.box-nav.last {
				padding-right: 0;
			}
			
			&.box-symbol {
				width: auto;
				padding: 0;
				display: none;
				
				.anyflexbox & {
				    width: 130px;
					display: flex;
   					align-items: flex-end;
				}
				
				svg {
					display: block;
					width: 100%;
					height: 100%; 
				}
			}
			
			&.box-social {
				padding: 0 0 0 1em;
				margin: 0;
				vertical-align: bottom;
				text-align: center;
				clear: both;
				width: 100%;
				
				.anyflexbox & {
					width: auto;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding-top: 2em;
				}
				
				#language-page-selector {
					display: none;
					margin-bottom: 1em;
					position: relative;
					
					.js & {
						display: block;
					}
					
					.label {
						background: $color-pyrotek-red url(/themes/yamltheme/client/dist/images/languages.svg) no-repeat right .75rem center;
						background-size: auto auto;
						background-size: 1.125rem;
						text-transform: uppercase;
						color: #fff;
						border: 2px solid #fff;
						border-radius: 2em;
						padding: 0.5em 2.5em 0.5em 1em;
						cursor: pointer;
						
						&:hover {
							background-color: $color-pyrotek-red-dark;
						}
					}
					
					ul {
						display: none;
						position: absolute;
						z-index: 1;
						width: 100%;
						margin: 0;
						padding: 0.25em 1em;
						float: none;
						text-align: left;
						
						li {
							margin: 0 0 0.25em 0;
							background: $color-pyrotek-red;
							border: 1px solid #fff;
							
							a {
								padding: 0.25em 0.5em;
								
								&:hover,
								&:active,
								&:focus {
									background: $color-pyrotek-red-dark;
									text-decoration: none;
									outline: 0 none;
								}
							}
							
						}
					}
					
					.anyflexbox & {
						width: 100%;
					}
				}
				.social-links {
					display: block;
					
					.js & {
						display: inline-block;
						text-align: right;
					}
					.anyflexbox & {
						margin-top: auto;
					}
				}
			}
			
		}
	}
	
	h3 { 
		color: #fff; 
		font-size: 1em; 
		margin: 0; 
		text-transform: uppercase;
	}
	a { 
		text-decoration: none; 
		color: #fff;
		
		&:focus,
		&:hover,
		&:active  {
			text-decoration: underline;
			color: #fff;
		} 
	}
	dl { 
		margin: 0.5em 0 0 0;
		font-size: 0.875em;
		overflow: hidden; 
		 
		dt { width: 2em; margin-top: 0.5em;}
		dd { margin-left: 2em; margin-top: 0.5em; }
	}
	
	ul { 
		margin: 0.5em 0 0 0; 
		font-size: 0.875em;
		list-style-type: none; 
		float: left;  
		
		li { 
			margin: 0.5em 0 0 0; 
			padding: 0;
		
			a { display: block; }
		}
	}
}

/*-------------------------------------------------------------------------*/

#top-link {
	position: fixed;
	bottom: 1em;
	right: 1em;
	background: #fff;
	text-align: center;
	border-radius: 1em;
	z-index: 10000;
	
	a {
		display: block;
		padding: 0.75rem 0.5rem 1rem;
		overflow: hidden;
		color: $color-pyrotek-red;
	}
	a:focus,
	a:hover,
	a:active  {
		text-decoration: none;
		color: $color-pyrotek-red-light;
	} 
	svg {
		font-size: 1.5em;
		margin-bottom: 0.2rem;
	}
	span {
		display: block;
		font-size: 0.75rem;
	}
}

/*-------------------------------------------------------------------------*/

footer { 
	background: $color-box; 
	color: $color-body-dark; 
	
	.ym-wbox { padding: 0.5em 1em; }
	.copyright { display: block; float: left; font-size: 0.6666em; line-height: 1.5em; }
	.reference { display: block; float: right; font-size: 0.6666em; line-height: 1.5em; }
	#nav-footer { 
		margin: 0; 
		padding: 0; 
		overflow: hidden; 
		float: left; 
		
		li { 
			display: inline; 
			float: left; 
			list-style: none outside none; 
			line-height: 1.5em; 
			font-size: 0.6666em; 
			padding: 0 0 0 1em; 
			margin: 0 0 0 1em; 
			background-image: none; 
			border-left: 1px solid $color-body-dark; 
		}
	}
	a { 
		color: $color-body-dark; 
		
		&:focus,
		&:hover,
		&:active { text-decoration: underline; }
	}
}

/*-------------------------------------------------------------------------*/

.ym-skiplinks a.ym-skip:focus,
.ym-skiplinks a.ym-skip:active {
	color:#fff;
	background:#333;
	border-bottom:1px #000 solid;
	padding:10px 0;
	text-align: center;
	text-decoration:none;
}
	
.visuallyhidden:not(:focus):not(:active) {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}
	