.listboxes {
	padding-top: 2.6em;
}

.listbox {
	margin-top: 4em;

	h2,
	h3 { 
		margin: 0;
		padding: 0.3em 0.5em 0 0.5em;
		border-top: 1px solid $color-box;
		border-right: 1px solid $color-box;
		border-left: 1px solid $color-box;
		border-radius: 0 0.7em 0 0;
	}
	h2 { padding: 0.24em 0.4em 0 0.4em}
	
	& > ul {
		list-style-type: none;
		overflow: hidden;
		margin: 0;
		padding: 0 0.5em 0.5em 0.5em;
		border-left: 1px solid $color-box;
		
		li {
			margin: 0.5em 0 0 0;
			padding: 0;
			
			a {
				display: inline-block;
				padding: 0.1em 0.5em;
				background: transparent;
				color: $color-body;
				
				&:focus,
				&:hover,
				&:active { 
					text-decoration: none; 
					outline: 0 none;
					background: $color-box-light;
				}
			}
		}
		
		&.tagcloud {
			padding: 0.5em 1em;
			
			li { 
				display: inline-block; 
				margin: 0.1em 0.1em 0 0; 
				
				a {
					display: table-cell;
					vertical-align: middle;
					height: 2em;
					padding: 0.1em 0.5em;
					background: $color-box-very-light;
					color: $color-body;

					&:focus,
					&:hover,
					&:active { 
						text-decoration: none; 
						outline: 0 none;
						background: $color-box-light;
					}
					
					span {
						display: block;
						line-height: 1em;
						
						&.weight1 { font-size: 0.50em; }
						&.weight2 { font-size: 0.625em; }
						&.weight3 { font-size: 0.75em; }
						&.weight4 { font-size: 0.875em; }
						&.weight5 { font-size: 1em; }
						&.weight6 { font-size: 1.125em; }
						&.weight7 { font-size: 1.25em; }
						&.weight8 { font-size: 1.375em; }
						&.weight9 { font-size: 1.5em; }
						&.weight10 { font-size: 1.625em; }
					}
					
				}
			}
		}
	}
}
