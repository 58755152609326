ul.tiles {

	list-style-type: none;
	overflow: hidden;
	margin: 0 -0.5em;

	@supports (display: grid) {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 1em;
		justify-content: center;
		margin: 0;
	}

	hidden { display: none; }

	.tile {
		display: block;
		float: left;
		flex: 0 0 auto;
		width: 20%;
		margin: 1em 0;

		@supports (display: grid) {
			width: auto;
			margin: 0;
		}

		.tile-wrapper,
		a {
			display: block;
			height: 100%;
			padding: 1em;
			margin: 0 0.5em;
			border: 1px solid $color-box;
			border-radius: 0 1.5em 0 0;
			background-color: $color-box-very-light;
			text-align: center;
			color: $color-body;

			@supports (display: grid) {
				margin: 0;
			}
		}
		a:focus,
		a:hover,
		a:active {
			text-decoration: none;
			outline: 0 none;
			background-color: $color-box-light;
		}


		h2,
		h3 {
			margin: 0;
			font-size: 125%;
		}
		p {
			font-size: 0.875em;
			margin-top: 0.6em;
		}
		p.meta {
			font-size: 0.75em;
			margin-top: 1.2em;
		}
		img {
			border-radius: 0px 1.1em 0px 0px;
			margin: 0 0 1em 0;
			background: #fff;
			width: 100%;
		}

		&.hidden {
			display: none;
		}
	}

	&.left {
		justify-content: flex-start;
	}

	.tile:nth-of-type(5n+1) {
		clear: left;
	}
	&.includes-add {
		.tile:nth-of-type(5n+1) {
			clear: none;
		}
		.product:nth-of-type(5n-1)
		 {
			clear: left;
		}
		grid-auto-flow: row dense;
	}

	&.large {
		grid-template-columns: repeat(4, 1fr);

		.tile {
			width: 25%;

			@supports (display: grid) {
				width: auto;
			}

			&:nth-of-type(5n+1) {
				clear: none;
			}
			&:nth-of-type(4n+1) {
				clear: left;
			}
		}
	}

	&.documents {
		.tile a img {
			display: inline-block;
		}
		.language {
			display: block;
			text-align: right;
			margin-top: -0.5em;
			margin-bottom: 0.5em;

			span {
				display: inline-block;
				background: $color-pyrotek-red;
				color: #fff;
				font-size: 0.75em;
				line-height: 1.2em;
				padding: .2em .5em;
			}
		}
	}

	&.slides {
		display: flex;
		grid-template-columns: unset;
		justify-content: unset;
		grid-gap: 0;
		margin: 0;

		.tile {
			width: 227px;
			margin: 0 0.5em 0 0;
		}
	}

	& .ad {
		width: 40%;

		@supports (display: grid) {
			width: auto;
			grid-column: 4 / 6;
		}

		a {
			position: relative;
			background-size: cover;
			padding: 0;
			overflow: hidden;
			text-align: left;

			.content {
				display: block;
				width: 65%;
				height: 100%;
				min-height: 13.7em;
				padding: 3em 1em 1em;
				background: #fff;
				opacity: 0.85;
				filter: alpha(opacity=80);
				color: $color-body-dark;
			}
		}
	}

	& .video-ad {
		width: 40%;
		border: 1px solid $color-box;
		border-radius: 0 1.5em 0 0;
		background-color: $color-box-very-light;
		overflow: hidden;

		@supports (display: grid) {
			width: auto;
			grid-column: 4 / 6;
		}

		.content {
			.video {
				margin: 0;
			}
		}
	}
}

.tile.header {
	width: 100%;

	.header {
		border: 1px solid $color-pyrotek-red;
		border-radius: 0 1.5em 0 0;
		background: $color-pyrotek-red;
		color: #fff;
		padding: 1em;

		h3 {
			margin: 0;
			color: #fff;
			font-size: 1.125em;
		}
	}

	.content {
		border: 1px solid $color-box-dark;
		padding: 0.5em 1em 1em 1em;

		h4 {
			margin-top: 1.3em;
			font-size: 120%;

			&:first-child {
				margin-top: 0.4em;
			}
		}
		ul {
			list-style: none;
			margin: 0;

			li {
				margin: 0.5em 0 0 0;

				a {
					color: $color-body;
				}
				a:focus,
				a:hover,
				a:active {
					text-decoration: none;
					outline: 0 none;
					color: $color-pyrotek-red;
				}

			}
		}
	}

}
