#col3 form {
	margin-top: 1em;
}

/* FIELDSETS
---------------------------------------- */
fieldset {
	border: 0;
}

.field {
	margin-top: 1em;
}

/* ACTION BUTTONS
---------------------------------------- */
.Actions { margin-top: 1em;}

a.button,
input.action {
	background: $color-pyrotek-red; 
	display: inline-block;
	color: #fff;
	border: 1px solid $color-pyrotek-red;
	font-size: 1em;
	line-height: 1.2em;
	padding: 0.4em 1.2em;
	text-decoration: none;
}
a.button:hover,
input.action:hover {
	background: $color-pyrotek-red-dark;
	border: 1px solid $color-pyrotek-red-dark;
	cursor: pointer;
}
a.button:active,
input.action:active {
	position:relative;
	top:1px;
	cursor: pointer;
}

a.button.reset {
	background: $color-box-light;
	border: 1px solid $color-box-light;
	color: $color-body;
}
a.button.reset:hover {
	background: $color-box;
	border: 1px solid $color-box;
	color: $color-body-dark;
}

/* FIELD FORMATING
---------------------------------------- */
form ul.optionset {
	list-style: none;
}
.middleColumn {
 	display: block;
 	margin: 0;
	background: transparent;
	clear: left;
}
	#Sidebar .middleColumn {
		margin: 0 0 0 8px;
		display: inline;
	}
	
	div.tab .field .middleColumn {
		clear: none !important;
 		margin: 0 !important;
		width: auto;
	}
	
	.middleColumn .middleColumn {
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
	
	.middleColumn .message {
		margin: 0;
	}
	
/* INPUT BOXS
---------------------------------------- */

span.readonly, input.text, textarea, select {
	padding: 0.5em;
	border-radius: 0.2em;
	border: 1px solid $color-box;
	color: $color-body;
	font-size: 1em;
	width: 95%;
	max-width: 400px;
	font-family: $font-stack-body;
	
	.fonts-loaded & {
		font-family: $font-stack-body-full;
	}
}
textarea {
	font-size: 0.9em;
	max-width: 500px;
}
input.text:focus, textarea:focus, select:focus {
	color: #000;
}

span.readonly { display: block; color: #767676; border: 1px solid #cdcdcd; font-style: italic; background: #fafafa; }


/* LABELS
---------------------------------------- */
label {
 	display: block;
 	margin: 0 0 0.2em 0;
	font-size: 1em;
}

label.right {
	display:inline;
}

/* VALIDATION
---------------------------------------- */
/* js validator */
input.error, textarea.error, select.error{
	border-color: $color-pyrotek-red; 
	margin: 0; 
	background: transparent;
}
.field .message.error,
label.error { 
	display: block;
	margin-bottom: 1em; 
	margin-top: 0.5em; 
	color: $color-pyrotek-red; 
	border: none; 
	background: transparent; 
	padding: 0; 
}
/* silverstripe validator */
input.holder-required, 
input.holder-validation, 
textarea.holder-required, 
textarea.holder-validation, 
select.holder-required , 
select.holder-validation {
	border-color: $color-pyrotek-red;
}
span.message.required, 
span.message.validation {
	display: block;
	margin: 0.5em 0 0 0;
	padding: 0; 
	color: $color-pyrotek-red; 
	border: 0; 
}
.required-message { font-style: italic; }

.field.requiredField label:after {
	content: " *";
}
.field.requiredField label.error:after {
	content: "";
}
.field.optionset.requiredField > legend:after {
	content: " *";
}
.field.optionset.requiredField label:after {
	content: "";
}

/* Radiobuttons
---------------------------------------- */
.optionset {
	.middleColumn div {
		overflow: hidden;
		margin: 6px 0;
		clear: both;
		padding: 0; 
		background-image: none;
		
		input {
			float: left;
			margin: 2px 6px 0 6px;
		}
		
		label {
			padding: 0 0 0 30px;
			display: block;
		}
	
	}
}

/* Checkboxes
---------------------------------------- */
div.checkbox input {
	float: left;
	margin: 0 6px 0 6px;
}
div.checkbox label {
	padding: 0 0 0 30px;
	display: block;
}

/* Checkable Options
---------------------------------------- */
.checkableoption {
	clear: both;
	position: relative;
	display: block;
	margin: 0 0 8px;
	padding: 0 0 8px;
}
.checkableoption:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
.checkableoption .field {
	margin-bottom: 0;
    margin-left: 6em;
    padding: 0.5em;
    border-top: 1px solid rgba(201, 205, 206, 0.8);
    border-bottom: 1px solid rgba(201, 205, 206, 0.8);
}
.checkableoption .CompositeField .field {
	border-top: none;
}
.checkableoption .CompositeField .field:first-child {
	border-top: 1px solid rgba(201, 205, 206, 0.8);
}
.checkableoption .CompositeField .field:last-child {
	border-bottom: 1px solid rgba(201, 205, 206, 0.8);
    box-shadow: 0 1px 0 rgba(244, 245, 245, 0.8);	
}
.checkableoption .field label.left {
	width: 98px;
}
.checkableoption .field.optioncheckbox {
	position: absolute;
	top: 0;
	bottom: 8px;
	background-color: #e3e3e3;
	padding: 0.7em 0.5em 0.5em 0.5em;
	margin: 0;
	width: 5em;
	border-radius: 0.2em 0 0 0.2em;
	border-bottom: 1px solid rgba(201, 205, 206, 0.8);
	border-top: 1px solid rgba(201, 205, 206, 0.8);
	border-left: 1px solid rgba(201, 205, 206, 0.8);

}
.checkableoption .field.optioncheckbox .checkbox {
	float: none;
	display: block;
	margin: 0 auto 0.5em;
}
.checkableoption .field.optioncheckbox label {
	display: block;
	text-align: center;
	padding-left: 0;
}

/* confirmed paassword field
---------------------------------------- */
.showOnClick .showOnClickContainer { 
/* used to hide a container by-default when loading it so there is no "flash" as the content loads */
display: none; }
