/* -------------------------------------------------------------------------- *\
 * Necessary styling for the dialog to work
 * -------------------------------------------------------------------------- */

/**
 * When `<dialog>` is properly supported, the overlay is implied and can be
 * styled with `::backdrop`, which means the DOM one should be removed.
 */
[data-a11y-dialog-native] .dialog-overlay {
  display: none;
}

/**
 * When `<dialog>` is not supported, its default display is `inline` which can
 * cause layout issues.
 */
dialog[open] {
  display: block;
}

.dialog[aria-hidden="true"] {
  display: none;
}

/* -------------------------------------------------------------------------- *\
 * Styling to make the dialog look like a dialog
 * -------------------------------------------------------------------------- */

.dialog-overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.66);
}

.dialog-content {
  background-color: rgb(255, 255, 255);
  border: 0 none;
  border-top: 2em solid $color-pyrotek-red;
  border-radius: 0.25em;
  box-shadow: 0px 0px 0.6em 0.3em rgba(0,0,0,0.2);
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  
  p {
  	margin: 0;
  }
}

/* -------------------------------------------------------------------------- *\
 * Extra dialog styling to make it shiny
 * -------------------------------------------------------------------------- */

@keyframes fade-in {
  from { opacity: 0;  }
  to { opacity: 1;  }
}

@keyframes appear {
  from { transform: translate(-50%, -40%); opacity: 0; }
  to { transform: translate(-50%, -50%); opacity: 1; }
}

.dialog:not([aria-hidden='true']) > .dialog-overlay {
  animation: fade-in 200ms 1 both;
}

.dialog:not([aria-hidden='true']) > .dialog-content {
  animation: appear 400ms 150ms 1 both;
}

.dialog-content {
  padding: 1em;
  max-width: 90%;
  width: 600px;
}


@media screen and (min-width: 700px) {
  .dialog-content {
    padding: 2em;
  }
}

.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.9);
}

.dialog-close {
	background: $color-pyrotek-red; 
	display: inline-block;
	color: #fff;
	border: 0;
	font-size: 1em;
	line-height: 1.2em;
	padding: 0.4em 1.2em;
	text-decoration: none;
	margin-top: 1em;

	&:hover {
		background: $color-pyrotek-red-dark;
		cursor: pointer;
	}
	&:active {
		position:relative;
		top:1px;
		cursor: pointer;
	}
}
