.productfamilypage,
.productdetailpage {
	
	.layout-band.background {
		background: $color-box-light;
		
		.ym-wbox {
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: left top;
		}
	}
	
	.layout-band.technical-data {
		background: $color-box-very-light;
		
		.tile.documents {
			margin-top: 2em;
			
			.content {
				background: #fff;
			}
		}
	}
	
	.layout-band.casestudies {
		background: $color-box-very-light;
	}
	
	.layout-band.documents {
		margin: 0;
		
		.ym-wbox {
			padding-top: 0;
			padding-bottom: 0;
			
			.header {
				background: $color-pyrotek-red;
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 1em;
				padding: 0.9em 1em;
				
				@media screen and ( min-width: 77em ) {
					padding: 0.9em 1.9em;
				}
				
				h2 {
					font-size: 112.5%;
					line-height: 1em;
					margin: 0;
					color: #fff;
				}
				
				form {
					overflow: hidden;
					display: flex;
					align-items: center;
					
					fieldset {
						float: left;
					}
					.field {
						float: left;
						margin: 0;
						
						label {
							position: absolute;
							width: 1px;
							height: 1px;
							margin: -1px;
							border: 0;
							padding: 0;
							white-space: nowrap;
							clip-path: inset(100%);
							clip: rect(0 0 0 0);
							overflow: hidden;
						}
						select {
							font-size: 0.875em;
							padding: 0.1em 1em 0.1em 0.5em;
							border-radius: 0;
							
							option {
								padding: 0 0.5em;
							}
						}
					}
					.Actions {
						float: right;
						margin: 0;
						
						input.action {
							border: 1px solid #fff;
							font-size: 0.875em;
							padding: .2em 1em;
							
							.js & {
								display: none;
							}
						}
					}
				}
				
			}
			.content {
				padding: 0;
				
				p {
					margin: 1em 1.9em;
				}
				
				table {
					width: 100%;
					margin: 0;
					border: 0;
					
					span {
						display: none;
					}
					
					tr {
						&.even td {
							background:$color-box-very-light;
						}
						&:hover td { 
							background:$color-box-light; 
						}
					}
					
					td {
						border: 0;
						line-height: 1em;
						padding: 0.8em 0.5em;
						
						&:first-child {
							padding-left: 1em;
							
							@media screen and ( min-width: 77em ) {
								padding-left: 1.9em;
							}
						}
						&:last-child {
							padding-right: 1em;
							
							@media screen and ( min-width: 77em ) {
								padding-right: 1.9em;
							}
						}
						
						.js & {
							cursor: pointer;
							
							a {
								color: $color-body;
								text-decoration: none;
							}
						}
						
						.icon {
							vertical-align: bottom;
						}
					}
				}
			}
				
		}
	}
	
	
}

.product-filter { 
	margin-top: 0;
	 
	.ym-wbox {
		padding: 0.5em 1em 0.2em;
		overflow: hidden;
	}
	
	label { 
		float: left; 
		width: 8em; 
		padding: 0.3em 0;
		font-weight: 600; 
	}

	.filter-group { 
		margin-left: 8em;
		overflow: hidden; 

		a,
		a:visited {
			float: left; 
			color: $color-body-dark;
			background: $color-box-lighter; 
			font-size: 1em;
			text-decoration:none;
			display:block;
			padding: 0.3em 0.8em;
			margin: 0 0.3em 0.3em 0;
		}
		a.current,
		a:hover,
		a:focus,
		a:active  {
			text-decoration: none;
			outline: 0 none;
			background: $color-box-very-dark;
			color: #fff;
			text-shadow: 1px 0 0 currentColor;
		}
		a.hidden {
			display: none;
		}

	}
	
	.filter-main {
		background: $color-box-very-light;
	}
	.filter-sub {
		background: $color-box-lighter;
		
		hidden,
		&.hidden {
			display: none;
		}
		
		a,
		a:visited {
			background: $color-box-light;
		}
		a.current,
		a:hover,
		a:focus,
		a:active  {
			background: $color-box-very-dark;
		}
		
	}
	.filter-label {
		color: $color-pyrotek-red;
		font-weight: 600;
	}
	

}

.product-list {
	margin: 0;
	
	.ym-wbox {
		padding-top: 1em;
	}
	
}
