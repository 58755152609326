.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	margin: 0.5em 0 0;

	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 0 !important;
	}
}

.vjs-afterglow-skin video {
    background-color: transparent !important;
}

.video-js, .vjs-caption-settings {
    background-color: transparent !important;
}

.vjs-afterglow-skin .vjs-big-play-button {
    font-size: 1.5em !important;
	width: 2.5em !important;
	height: 1.5em !important;
	margin-left: -1.25em !important;
	margin-top: -0.75em !important;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
    background: #ddd !important;
    background: rgba(0,0,0,.6) !important;
}
.vjs-afterglow-skin .vjs-big-play-button::before {
	line-height: 1em !important;
	font-size: 1em !important;
	text-shadow: none !important;
	text-shadow: none !important;
	height: 1em !important;
	top: 50% !important;
	left: 0.1em !important;
	margin-top: -0.5em !important;
}