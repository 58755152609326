/*
 * jQuery FlexSlider v2.7.1
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;

  &.tileslider {
  	padding-bottom: 3em;
  }
}
.flexslider .slides > li {
	display: none;

	&:first-child {
		display: block;
	}
}
.flexslider.tileslider .slides > li {
  display: block;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  position: relative;
  zoom: 1;
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
}
.flex-viewport {
  max-height: unset;
}
.loading .flex-viewport {
  max-height: unset;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 2.5em;
  height: 2.5em;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav li {
	margin: 0;
	line-height: 1em;
}
.flex-direction-nav a:before {
  display: inline-block;
  content: '';
  height: 2.5em;
  width: 2.5em;
  background: transparent url(/themes/yamltheme/client/dist/images/slider-left.svg) no-repeat top left;
  background-size: 100%;
}
.flex-direction-nav a.flex-next:before {
  background-image: url(/themes/yamltheme/client/dist/images/slider-right.svg);
}
.flex-direction-nav .flex-prev {
  left: -3em;
}
.flex-direction-nav .flex-next {
  right: -3em;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 1em;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 1em;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}
.flex-control-nav {
	display: flex;
	position: absolute;
	bottom: -0.5em;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 2em;
	color: #ee3426;
	background: #fff;
	text-align: center;
	line-height: 1em;

	.tileslider & {
		background: transparent;
		bottom: .5em;
		padding: 0;
	}
}
.flex-control-nav li {
  margin: 0 0.3em;
  display: inline-block;
  line-height: 1em;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 1em;
  height: 1em;
  display: block;
  background: #fff;
  border: 1px solid $color-pyrotek-red;
  border-radius: 50%;
  cursor: pointer;
  text-indent: -9999px;
}
.flex-control-paging li a:hover {
  background: $color-pyrotek-red;
}
.flex-control-paging li a.flex-active {
  background: $color-pyrotek-red;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
