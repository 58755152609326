@media all
{
	html * { font-size:100.01%; }

	pre, code, kbd, samp, var, tt {
		font-family: $font-stack-pre;
	}

	body {
		font-family: $font-stack-body;
		font-weight: 300;
		font-size: 100.00%; /* 10px:62.50%, 11px:68.75%, 12px:75.00%, 13px:81.25%, 14px:87.50% */
		color: $color-body;

		.fonts-loaded & {
			font-family: $font-stack-body-full;
		}
	}
	
	/*--- Additional columns ---------------------------------------------------------------------------------------*/
	
	.subcolumns { margin-top: 0; }
	.subcl { padding: 0 0.5em 0 0; }
	.subcr { padding: 0 0 0 0.5em; }

	/*--- Headings ---------------------------------------------------------------------------------------*/

	/* see editor.css */
	
	/* --- general text formatting | Allgemeine Textauszeichnung ------------------------------------------ */

	p { line-height: 1.5em; margin: 1.2em 0 0 0; }
	
	address { font-style: normal; line-height: 1.5em; margin: 1.2em 0 0 0; }

	blockquote, cite, q { font-family: $font-stack-quote; font-style:italic; }
	blockquote {
		background: #f9f9f9;
		border-left: 10px solid #ccc;
		margin: 1.5em 0 0 0;
		padding: 1em;
		quotes: "\201C""\201D""\2018""\2019";
	}
	blockquote:before {
		color: #ccc;
		content: "\201C";
		font-size: 4em;
		font-weight: bold;
		line-height: 0.1em;
		margin-right: 0.25em;
		vertical-align: -0.4em;
	}
	blockquote p {
		display: inline;
	}

	strong,b { font-weight:600;}
	em,i { font-style:italic; }

	big { font-size:116.667%; }
	small { font-size:91.667%; }
 
	pre { line-height:1.3em; margin:1em 0 0 0; font-family: $font-stack-pre; overflow-x: auto;
    white-space: pre-wrap; word-wrap: break-word; padding: 0.5em; border: 1px solid #ddd;}
	pre, code, kbd, tt, samp, var { font-size:100%; }
	kbd, samp, var, tt { color:#666; font-weight:bold; }
	var, dfn { font-style:italic; }

	acronym, abbr { border-bottom:1px #aaa dotted; font-variant:small-caps; letter-spacing:.07em; cursor:help; }

	sub, sup { font-size:85.7%; line-height:0; }

	hr { color:transparent; background:transparent; margin:0; padding:0; border:0; display: block; width: 100%; overflow: hidden; }

	/*--- Links ----------------------------------------------------------------------------------------- */

	/* see editor.css */

	/* --- Lists | Listen	-------------------------------------------------------------------------------- */

	/* see editor.css */
	
	/* --- images (with optional captions) | Bilder (mit optionaler Bildunterschrift) ------------------ */
	
	/* see editor.css */

	/* --- Generic Content Classes  -----------------------------------------------------------------------*/
	
	.box.info,
	.box.success,
	.box.warning,
	.box.error {
		margin:2em 0; 
		color: #333;
		background: #f4f4f4;
		font-style: italic; 
		padding: 0.5em 0.75em;

	}
	.message { 
		padding: 0.2em 1em;
	}
	.message.good,
	.box.success { background:#d3f4d8; border: 1px solid #3b8144;  }
	.message.warning,
	.box.warning { background:#ece4c7; border: 1px solid #d3b448; }
	.message.bad,
	.box.error { background:#ead4d0; border: 1px solid #c5361d; }
	
	div.success p,
	div.error p,
	div.warning p { margin: 0.4em 0; } 
		
	.center { text-align: center;}
	.description { font-size: 0.875em; margin-top: 0.4em; }
	.meta { font-size: 0.875em; margin-top: 1.5em; }
	.abstract { font-weight: bold; }
	
	.nomargin { margin: 0;}
	
	/* --- Tables | Tabellen --------------------------------------------------------------------------------*/

	/* see editor.css */
	
		
}