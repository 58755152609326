.block_area .block_wrapper.twitterblock_wrapper {
	.twitterblock {
		&.centered .tweet-wrapper {
			text-align: center;
		}
		.tweet-wrapper {
			margin: 1.2em 0 0 0;
		}
		.tweet {
			display: inline-block;
		}
	}
}