.CaseStudyGroup {
	margin-top: 3em;

	&__Header {
		margin-bottom: 1em;
		padding-bottom: 0.5em;
		border-bottom: 1px solid $color-box-lighter;
		
		@media screen and ( min-width: 30em ) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		
		&--title {
			margin-top: 0;
			
			@media screen and ( min-width: 30em ) {
				margin-right: auto;
			}
		}
		&--label {
			display: block;
			color: $color-box-darker;
			margin-right: 1em;
			font-size: 0.875em;
		}
		&--link {
			display: block;
			margin-top: 0.5em;
			
			@media screen and ( min-width: 30em ) {
				margin: 0;
			}
		}
	}
	
}

.casestudypage {
	
	.maincontent {
	
		.ym-wbox {
			padding: 1em 35% 0 0;
			margin-top: 2em;
			position: relative;
		}
		article {
			position: relative;
			z-index: 200;
		}
		
		h1 {
			padding-right: 25%;
			padding-left: 0.33em;
		}
		.padded {
			position: relative;
			padding: 2em 2em 2em 33.3%;
			background: $color-box-lighter;
			
			&.first {
				border-radius: 0 2em 0 0;
				margin-top: 1em;
			}
			&.last {
				padding-bottom: 3em;
			}
			
			h2:first-child {
				position: absolute;
				top: 1em;
				left: 0.5em;
				width: 28%;
				width: calc(33.3% - 1em);
				font-size: 200%;
				margin: 0;
			}
			p:first-of-type {
				margin: 0;
			}
		}
		.image {
			position: absolute;
			width: 50%;
			height: 100%; 
			top: 0;
			right: 0;
			z-index: 100;
			overflow: hidden;
			
			img {
				margin: 0;
				width: auto;
				height: auto;
				max-width: none;
				min-height: 100%;
				min-width: 100%;
				position: absolute;
				top: 0;
				right: 0;
			}
		}		
	}
	
	.layout-band.callout {
		min-height: 10em;
		margin: 0;
	
		.ym-wbox {
			position: relative;
			padding: 0;
			
			img { margin: 0; }
			
			.callout {
				position: absolute;
				font-size: 2em;
				color: #fff;
				top: -1.5em;
				right: 2em;
				width: 50%;
				background: $color-pyrotek-red;
				padding: 1em 1.5em 1.2em;
				border-radius: 0 1em 0 0;
				z-index: 300;
			}
		}
	}
	
	.layout-band.documents {
		margin: 0;
		
		.ym-wbox {
			padding: 0;
			
			.header {
				line-height: 1em;
				padding: 0.9em 1em;
				
				@media screen and ( min-width: 77em ) {
					padding: 0.9em 1.9em;
				}
				
				h2 {
					font-size: 112.5%;
					line-height: 1em;
					margin: 0;
					color: #fff;
				}
			}
			
			.ym-gbox-left {
				padding: 0;
				
				img {
					margin: 0;
				}
				.header {
					background: $color-box-very-dark;
					padding-top: 1.55em;
				}
			}
			.ym-gbox-right {
				padding: 0;
				
				.header {
					background: $color-pyrotek-red;
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					form {
						overflow: hidden;
						display: flex;
						align-items: center;
						
						fieldset {
							float: left;
						}
						.field {
							float: left;
							margin: 0;
							
							label {
								position: absolute;
								width: 1px;
								height: 1px;
								margin: -1px;
								border: 0;
								padding: 0;
								white-space: nowrap;
								clip-path: inset(100%);
								clip: rect(0 0 0 0);
								overflow: hidden;
							}
							select {
								font-size: 0.875em;
								padding: 0.1em 1em 0.1em 0.5em;
								border-radius: 0;
								
								option {
									padding: 0 0.5em;
								}
							}
						}
						.Actions {
							float: right;
							margin: 0;
							
							input.action {
								border: 1px solid #fff;
								font-size: 0.875em;
								padding: .2em 1em;
								
								.js & {
									display: none;
								}
							}
						}
					}
					
				}
				.content {
					padding: 0;
					
					p {
						margin: 1em 1.9em;
					}
					
					table {
						width: 100%;
						margin: 0;
						border: 0;
						
						span {
							display: none;
						}
						
						tr {
							&.even td {
								background:$color-box-very-light;
							}
							&:hover td { 
								background:$color-box-light; 
							}
						}
						
						td {
							border: 0;
							line-height: 1em;
							padding: 0.8em 0.5em;
							
							&:first-child {
								padding-left: 1em;
								
								@media screen and ( min-width: 77em ) {
									padding-left: 1.9em;
								}
							}
							&:last-child {
								padding-right: 1em;
								
								@media screen and ( min-width: 77em ) {
									padding-right: 1.9em;
								}
							}
							
							.js & {
								cursor: pointer;
								
								a {
									color: $color-body;
									text-decoration: none;
								}
							}
							
							.icon {
								vertical-align: bottom;
							}
						}
					}
				}
				
			}
		}
	}
	
	.layout-band.products {
		margin: 0;
		background: $color-box-very-light;
	}
	
}