.keyvisual {
	position: relative;
	overflow: hidden;
	padding-bottom: 1rem;
	width: 100%;
	text-align: center;

	.image {
		padding: 1.25rem 0 1.125rem;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		height: 18rem;

		picture {
			height: 100%;
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	.content {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		padding-top: 1px;
		padding-bottom: calc(1em + 1px);

		.ym-wrapper {
			height: 100%;
		}
		.ym-wbox {
			position: relative;
			padding: 0;
			height: 100%;
		}
		.mask {
			position: absolute;
			top: -2px;
			right: 1em;
			height: calc(100% + 4px);
			width: fit-content;
			fill: #fff;
		}
		.text-wrapper {
			position: absolute;
			display: table;
			left: 0;
			bottom: 3.5em;
			text-align: left;
			max-width: 70%;

			.text {
				display: table-cell;
				color: #fff;
				font-size: 200%;
				font-weight: 300;
				background: $color-pyrotek-red;
				padding: 0.3em 0 0.3em 0.5em;
				line-height: 1.5em;
			}
			.border {
				display: table-cell;
				color: $color-pyrotek-red;
				width: 20%;

				svg {
					position: absolute;
					top: 0;
					height: 100%;
					width: 100%;
					margin-left: -1px;
				}
			}
		}
	}
	.dots {
		position: absolute;
		bottom: 0.2em;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 2em;
		color: $color-pyrotek-red;
		background: #fff;

		svg {
		    display: inline-block;
		    width: 7em;
		    height: 1em;
		}
	}

	.slides {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex !important;

		& > li {
			margin: 0;
			padding: 0;
			position: relative;
		}

		.content {
			.mask {
				top: -1px;
				height: calc(100% + 2px);
			}
		}
	}

	& > .ym-wrapper > .ym-wbox {
		padding: 0;
	}

}
