.mapblock {
	
	& > h2 {
		& > * {
			vertical-align: middle;
		}
		.icon-globes {
			width: 2.21484375em;
		}
	}
	
	.map-columns {
	    display: table;
	    table-layout: fixed;
	    width: 100%;
	
		.map-column-one { /* map */
			float: left;
			width: 70%; 
			 
			.map-column-inner {
				padding-right: 1.5em;
			}
		}
		.map-column-two { /* form */
			 margin: 0 0 0 60%;
			 
			.map-column-inner {
				padding-left: 1.5em;
				overflow: hidden;
				
				form {
					margin-top: 2em;
				}
			}
		}
	}
	
	#map-canvas {
		width: 100%;
		height: 25em;
		margin: 1.2em 0 0;
		background: $color-box-light;
		
		img {
			margin: 0;
		}
	}
	
	.marker-box{
	    svg { 
	    	fill: $color-box-very-dark;
	    	width: 12px;
	    	height: 12px;
	    	position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
	    }
	    
	    &.current {
		    svg { 
		    	fill: $color-pyrotek-red;
		    	width: 24px;
		    	height: 24px; 
		    }
	    }
	}
	
	.map-info-element {
		display: none;
	}
	
	.mapinfo {
		margin: 2rem 0 0 0;
		dl { 
			margin: 0;
			dt { 
				width: 2rem; 
				color: $color-pyrotek-red;
				font-size: 1.4em;
				margin-top: 0.5rem;
				text-align: center;
				
				&.address {
					font-size: 1.8em;
				}
			}
			dd { 
				margin-left: 2.5rem; 
				margin-top: 0.5rem;
				font-size: 1.2rem;
				line-height: 1.2em;
				
				&.address {
					font-size: 1em;
				}

				h4 {
					margin: 0;
					font-size: 1.3rem;
					text-align: left;
					color: $color-pyrotek-red;
				}
			}
		}
	}
	
	h3 {
		font-size: 1.5rem;
		margin-top: 1.2rem;
		
		&.com-info {
			margin-top: 2rem;
			
			img {
				display: inline-block;
				vertical-align: middle;
				height: 1.2em;
				width: 1.2em;
			}
		}
	}
	p.com-info {
		margin-top: 0.5em;
	}
	
}

@media screen and ( max-width: 60em ) { /* 960px */
	
	.mapblock {
	
		.map-columns {
			.map-column-one { /* map */
				float: none; 
				width: 100%; 
				 
				.map-column-inner {
					padding-right: 0;
				}
			}
			.map-column-two { /* form */
				 margin: 0;
				 
				.map-column-inner {
					padding-left: 0;
				}
			}
		}
	}

}
