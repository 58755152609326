.contactpage {
	
	.contact-columns {
	    display: table;
	    table-layout: fixed;
	    width: 100%;
	
		.contact-column-one { /* map */
			float: right; 
			width: 60%; 
			 
			.contact-column-inner {
				padding-left: 1.5em;
			}
		}
		.contact-column-two { /* form */
			 margin: 0 60% 0 0;
			 
			.contact-column-inner {
				padding-right: 1.5em;
				overflow: hidden;
				
				form {
					margin-top: 2em;
				}
			}
		}
	}
	
	#local-office {
		display: none;
		
		.contact-phone,
		.contact-phone-local {
			font-size: 1.2rem;
			font-weight: 600;
		}
	}
	
	.contactinfo {
	
		& > h2 {
			margin-top: 0.5em;
		}
		
		.contactlisting {
			columns: 2;
			column-gap: 2em;
			
			&.separator {
				padding-top: 0.5rem;
				margin-top: 2rem;
				border-top: 1px solid $color-pyrotek-red;
			}
		}
	
		.office { 
			margin: 0;
			padding: 1.5em 0 0 0;
			break-inside: avoid;
			page-break-inside: avoid;
			overflow: hidden;
			
			h3 {
				margin: 0;
				font-size: 1.5rem;
				text-align: left;
				color: $color-pyrotek-red;
			}
			
			p,
			address {
				margin-top: 0.3em;
			}
			
			& > p {
				color: $color-pyrotek-red;
			}
			
			.contact-address-street,
			.contact-address-pobox-wrapper,
			.contact-address-country {
				display: block;
			}
		}
	}

}

@media screen and ( max-width: 40em ) { /* 640px */
	.contactpage {
		.contactinfo {
			.contactlisting {
				columns: auto;
				column-gap: auto;
			}
		}
	}
}