.block_area .block_wrapper.bannerblock_wrapper {
	padding: 0;
	
	.ym-wbox {
		padding: 0;
	}
	
	.bannerblock {
	
		& > a {
			display: block;
		}
	
		@media screen and ( max-width: 40em ) { /* 640px */
			.image {
				display: block;
			}
			.image-wide {
				display: none;
			}
		}
		
		@media screen and ( min-width: 40em ) { /* 640px */
			.image {
				display: none;
			}
			.image-wide {
				display: block;
			}
		}
	}
	
}