/**
 * This support file is used to style the WYSIWYG editor in the CMS
 */

body.mceContentBody {
	font-family: $font-stack-body;
	font-weight: 300;
	font-size: 87.50%; /* 10px:62.50%, 11px:68.75%, 12px:75.00%, 13px:81.25%, 14px:87.50% */
	color: $color-body;
}

/*--- Headings ---------------------------------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-stack-header;
	color: $color-header-light; 
	font-weight: 400;
	line-height: 1.2em;
	margin:1.4em 0 0 0;
}

.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6 {
	font-family: $font-stack-header-full;
}

h1 { font-size: 300%; margin-top: 0; }
h2 { font-size: 250%; color: $color-pyrotek-red; }
h3 { font-size: 200%; }
h4 { font-size: 150%; }
h5 { font-size: 112.5%; }
h6 { font-size: 100%; }

.homepage h1,
.landingpage h1 { text-align: center; color: $color-pyrotek-red; }

/* --- general text formatting | Allgemeine Textauszeichnung ------------------------------------------ */

p { line-height: 1.5em; margin: 1.2em 0 0 0; }
address { font-style: normal; line-height: 1.5em; margin: 1.2em 0 0 0; }

body.mceContentBody hr { color:transparent; background:transparent; margin:0; padding:0; border:0; border-bottom: 1px dotted #ddd; display: block; width: 100%; overflow: hidden;}

/*--- Links ----------------------------------------------------------------------------------------- */

a { color: $color-pyrotek-red; text-decoration: none; transition-duration: 0.15s; transition-property: color, text, background; transition-timing-function: linear, ease-in; }
a:focus,
a:hover,
a:active { text-decoration: underline; outline: 0 none; }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a { color: $color-pyrotek-red; }
h1 a:focus, h1 a:hover, h1 a:active,
h2 a:focus, h2 a:hover, h2 a:active,
h3 a:focus, h3 a:hover, h3 a:active,
h4 a:focus, h4 a:hover, h4 a:active,
h5 a:focus, h5 a:hover, h5 a:active,
h6 a:focus, h6 a:hover, h6 a:active { color: $color-pyrotek-red; text-decoration: underline; }

.morelink { margin: 1em 0 0 0; }
.backlink { float:right; padding: 0.3em 0.7em; background: #ccc; }
.backlink.bottom { margin-top: 2em; }
.backlink  a { color: #fff; }
.backlink  a:before {
	color: #fff;
	content: "<";
    font-size: 2em;
    line-height: 1em;
    margin-right: 0.25em;
    vertical-align: -0.15em;
}
.backlink  a:focus, .backlink  a:hover, .backlink  a:active {
	color: #fff; text-decoration: none;
}

a.image:focus,
a.image:hover,
a.image:active { text-decoration:none; outline: 0 none; }

.custom-button {
	a {
		&,
		&:visited {
			color: $color-pyrotek-red;
			background: transparent;
			border: 1px solid $color-pyrotek-red;
			font-size: 1em;
			text-decoration:none;
			text-transform: uppercase;
			font-weight: 400;
			text-align: center;
			display: inline-block;
			padding: 0.6em 2.5em;
			margin: 0;
		}
		&.current,
		&:hover,
		&:focus,
		&:active  {
			text-decoration: none;
			outline: 0 none;
			color: #fff;
			background: $color-pyrotek-red;
		}		
	}
}

/* fix links with svg inside in MS Edge: http://stackoverflow.com/questions/35367445/can-only-click-edges-of-svg-image */
a object,
a svg {
  pointer-events: none;
}


/* --- Lists | Listen	-------------------------------------------------------------------------------- */

ul, ol, dl { line-height:1.5em; margin: 1.2em 0 0 0; }
ul { list-style-type:disc; }
ul ul { list-style-type:circle; margin:0; }

ol { list-style-type:decimal; }
ol ol { list-style-type:lower-latin; margin-bottom:0; }

li { margin: 0.5em 0 0 1em; line-height:1.5em; }

dl { margin: 0; width: 100%; display: table; }
dt { 
	font-weight: normal; 
	width: 25%; 
	clear: both; 
	float: left; 
	margin-top: 0.8em;
}
dd { 
	margin: 0.8em 0 0 25%; 
	
	&:after {
		content: "";
		display: block;
		clear: both;
	} 
}
dl p,
dl address { margin: 0; }

/* --- images (with optional captions) | Bilder (mit optionaler Bildunterschrift) ------------------ */

img,
picture { 
	display: block; 
	max-width: 100%; 
	height: auto;
}

.typography img {
	margin: 1.6em 0 1em 0;
}
.typography img.no-margin { margin: 0; }

.typography img.left { float: left; margin: 0.2em 2em 0 0; }
.typography img.right { float: right; margin: 0.2em 0 0 1.5em; }
.typography img.center { margin-left: auto; margin-right: auto; }

div.captionImage { margin: 1.6em 0 0 0; }
div.captionImage img { margin: 0; }
div.captionImage.left { float: left; margin: 1.6em 2em 0 0; }
div.captionImage.right { float: right; margin: 1.6em 0 0 1.5em; }
div.captionImage.center { margin-left: auto; margin-right: auto; }
div.captionImage.left img,
div.captionImage.right img,
div.captionImage.center img { float: none; }
div.captionImage .caption { font-family: $font-stack-body; font-size: 0.938em; padding:0; margin:0.5em 0 0.8em; text-align: left; line-height: 1.5em; }

.no-js .lazyload,
.no-js .lazyload-picture { display: none; }

/* --- Generic Content Classes  -----------------------------------------------------------------------*/

.typography .small { font-size: 0.9em; margin: 0 !important; }

/* --- Tables | Tabellen --------------------------------------------------------------------------------*/

.typography table { width:auto; border-collapse:collapse; margin-bottom:0.5em; margin-top:2em; border-top:1px solid $color-box-dark; border-bottom:1px solid $color-box-dark; }
.typography table caption { font-variant:small-caps; }
.typography table.full { width:100%; }
.typography table.fixed { table-layout:fixed; }

.typography th, 
.typography td { padding:0.5em; vertical-align: top; border: 1px solid $color-box-dark; font-size: 1em; }
.typography th { background: $color-box-light; font-weight: 600;}
.typography th strong { font-size: 1.125em; } 
.typography thead th { border-bottom: 1px solid $color-box-dark; font-size: 1.125em; line-height: 1em; }

.typography tbody th { text-align:left; font-weight: bold; }
.typography tbody td { font-weight: 300; background: #fff; }

.typography tbody tr:hover td { background:$color-box-very-light; }
	
.typography table td p,
.typography table td address { margin:0.3em 0 0.3em 0; }
.typography table td img { margin: 0; }
	
.typography table.neutral { border: 0; }
.typography table.neutral tbody td,
.typography table.neutral tbody th { border-bottom: 0; padding: 0.5em 0.5em 0.5em 0; }
.typography table.neutral tbody tr:hover th,
.typography table.neutral tbody tr:hover td { background: transparent; }
