@media all {
	
	/* navigation */
	#outer-wrap {
		position: relative;
		overflow: hidden;
		width: 100%;
	}
	#inner-wrap {
		position: relative;
		width: 100%;
		background: #fff;
	}
	#nav-open,
	#nav-close {
		display: none;
		background: transparent;
		color: $color-pyrotek-red;
		font-size: 2.5em;
		padding: 0.1em 0.3em;
		text-decoration: none;
		line-height: 1em;
		border: none;
		position: absolute;
		top: .2em;
		left: .1em;
		z-index: 30;
		
		.icon {
			margin: 0;
			display: block;
		}
	}
	#nav-close {
		color: #fff;
	}
}

@media screen and ( max-width: 75em ) { /* 1200px */
	
	// set body background
	body { background: #333; }
	
	#logo {
		display: inline-block;
	}
	#mainnav { 
		display: none;
		clear: both;
		float: none;
		left: 0;
		margin: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 40;
		background: #333;
		padding-top: 7em;
		transition: all 0.3s; 
	}
	#nav-open {
		display: block;
	}
	
	// js not enabled: use target property 
	#mainnav:not(:target) {
		z-index: -50;
		overflow: hidden;
		height: 0;
		background: transparent;
	}
	#mainnav:target,
	#mainnav:target #nav-close {
		display: block;
	}
	#mainnav:target #nav-open {
		display: none;
	}
	
	// general 
	#mainnav .ym-hlist { 
		padding: 0; 
		margin: 0; 
		width: 100%;
	}
	#mainnav .ym-hlist ul {
		overflow: hidden;
		width: 100%; 
		border-bottom: 1px solid #3d3d3d;
	}
	#mainnav .ym-hlist ul li {
		position: relative; 
		font-size: 1em;
		clear: both;
		display: block;
		width: 100% !important;
		padding: 0;
		margin: 0;
		border-top: 1px solid #3d3d3d;
		background: transparent; 
		&.children {
			background: transparent;
		}
		a {
			display: block;
			width: 100%;
			color: #ccc;
			background: transparent;
			font-size: 1em;
			line-height: 1.2em;
			font-weight: normal;
			text-align: left;
			outline: none;
			padding: 0.8em 1em;
			margin: 0; 
		}
	}
	#mainnav .ym-hlist ul li a:focus, 
	#mainnav .ym-hlist ul li a:active, 
	#mainnav .ym-hlist ul li a:hover,
	#mainnav .ym-hlist ul li a.current,
	#mainnav .ym-hlist ul li a.section {
		text-decoration: none;
		outline: 0 none;
		color: #fff;
		background: transparent;
	}
	#mainnav .ym-hlist ul li a:focus, 
	#mainnav .ym-hlist ul li a:active, 
	#mainnav .ym-hlist ul li a:hover {
		background: #3d3d3d;
	}
	
	// arrow
	#mainnav .ym-hlist ul li > a.children {
	   	display: none;
	}
	.js #mainnav .ym-hlist ul li.children > a {
		width: 85%;
		float: left; 
	}
	.js #mainnav .ym-hlist ul li > a.children,
	.js #mainnav .ym-hlist ul li > a.children:hover,
	.js #mainnav .ym-hlist ul li > a.children:active,
	.js #mainnav .ym-hlist ul li > a.children:focus {
		display: block;
		float: right;
		width: 15%;
		text-align: center;
		padding: 0.9em 0;
		margin: 0;
		background: #333;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
		opacity: 0.2;
		svg {
			margin: 0 auto;
		}
	}
	.js #mainnav .ym-hlist ul li > a.children:hover {
		background: #3d3d3d;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
		opacity: 0.8;
	}
	.js #mainnav .ym-hlist ul li.children > a.children .icon-arrow-up {
		display: none;
	}
	.js #mainnav .ym-hlist ul li.children > a.children .icon-arrow-down {
		display: block;
	}
	.js #mainnav .ym-hlist ul li.children.section > a.children .icon-arrow-up {
		display: block;
	}
	.js #mainnav .ym-hlist ul li.children.section > a.children .icon-arrow-down {
		display: none;
	}
	
	// levels 2+ 
	#mainnav .ym-hlist ul li > ul,
	#mainnav .ym-hlist ul li:focus > ul,
	#mainnav .ym-hlist ul li:hover > ul,
	#mainnav .ym-hlist ul li:active > ul {
		display: none;
		position: relative;
		max-height: 100em;
		max-width: 100%;
		width: 100%;
		overflow: hidden;
		transition: none;
	}
	#mainnav .ym-hlist ul li ul { padding: 0; border: 0; }
	#mainnav .ym-hlist ul li ul li { border-bottom: 0; font-size: 1em; padding: 0; margin: 0; }
	#mainnav .ym-hlist ul li ul li a { 
		background-color: transparent; 
		color: #ccc;
		padding: 0.8em 1em;
		margin: 0; 
	} 
	
	#mainnav .ym-hlist ul li > ul li a.current, 
	#mainnav .ym-hlist ul li > ul li a.section, 
	#mainnav .ym-hlist ul li > ul li a:focus, 
	#mainnav .ym-hlist ul li > ul li a:active, 
	#mainnav .ym-hlist ul li > ul li a:hover {
		background-color: #3d3d3d;
		color: #fff;
	}
	#mainnav .ym-hlist ul li ul ul { position: relative; left: 0; }
	
	// levels 
	#mainnav .ym-hlist ul li ul li a { padding-left: calc(1em + 10%); } // LTR 
	#mainnav .ym-hlist ul li ul li ul li a { padding-left: calc(1em + 20%); } // LTR 
	#mainnav .ym-hlist ul li ul li ul li ul li a { padding-left: calc(1em + 30%); } // LTR 
	#mainnav .ym-hlist ul li ul li ul li ul li ul li a { padding-left: calc(1em + 40%); } // LTR 	
	
	// open current 
	#mainnav .ym-hlist ul li.section > ul { display: block; max-height: 100em; }
	#mainnav .ym-hlist ul li ul li.children:hover > ul { display: none; }
	#mainnav .ym-hlist ul li ul li.section > ul,
	#mainnav .ym-hlist ul li ul li.section:hover > ul { display: block;  } 
	
	// js enabled 
	.js #mainnav {
		height: 100%;
		width: 40%;
		box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
		overflow: initial;
		background: #333;
	}
	.js #nav-close {
		display: block;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
		opacity: 0.6;
	}
	.js #nav-close:focus, .js #nav-close:hover {
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
		opacity: 1;
	}
	.js #mainnav {
		left: -40%;
	}
	.js #mainnav:not(:target) {
		z-index: auto;
	}
	.js #inner-wrap {
		left: 0;
	}
	.js.jsnav #mainnav {
		display: block;
	}
	.js.jsnav #inner-wrap {
		left: 40%;
	}
	
	// animations
	.csstransforms3d.csstransitions.js #mainnav {
		transform: translate3d(-100%, 0, 0);
		backface-visibility: hidden;
	}
	.csstransforms3d.csstransitions.js.jsnav #mainnav {
		left: 0;
	}
	.csstransforms3d.csstransitions.js #inner-wrap {
		left: 0 !important;
		transition: transform 500ms ease;
		backface-visibility: hidden;
	}
	.csstransforms3d.csstransitions.js.jsnav #inner-wrap {
		transform: translate3d(40%, 0, 0) scale3d(1, 1, 1);
	}
	.csstransforms3d.csstransitions.js #mainnav .ym-hlist {
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
		opacity: 0.6;
		transition: opacity 300ms 100ms ease, transform 500ms ease;
		transform: translate3d(40%, 0, 0) scale3d(0.9, 0.9, 0.9);
		transform-origin: 50% 0%;
	}
	.csstransforms3d.csstransitions.js.jsnav #mainnav .ym-hlist {
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

}


@media screen and ( max-width: 55em ) { /* 880px */
	
 	// js enabled 
	.js #mainnav {
		width: 60%;
		left: -60%;
	}
	.js.jsnav #inner-wrap {
		left: 60%;
	}
	
	// subnav 
	.csstransforms3d.csstransitions.js.jsnav #inner-wrap {
		transform: translate3d(60%, 0, 0) scale3d(1, 1, 1);
	}
	.csstransforms3d.csstransitions.js #mainnav .ym-hlist {
		transform: translate3d(60%, 0, 0) scale3d(0.9, 0.9, 0.9);
	}

}


@media screen and ( max-width: 40em ) { /* 640px */
	
	// js enabled 
	.js #mainnav {
		width: 80%;
		left: -80%;
	}
	.js.jsnav #inner-wrap {
		left: 80%;
	}
	
	// subnav 
	.csstransforms3d.csstransitions.js.jsnav #inner-wrap {
		transform: translate3d(80%, 0, 0) scale3d(1, 1, 1);
	}
	.csstransforms3d.csstransitions.js #mainnav .ym-hlist {
		transform: translate3d(80%, 0, 0) scale3d(0.9, 0.9, 0.9);
	}

}

