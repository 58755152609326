@media all
{
	
	/* --- social -------------------------------------------------------------------------------------------*/
	.content-footer { overflow: hidden; margin-top: 2em; border-top: 1px solid #d9d9d9; padding-top: 1em; }
	.content-footer .share { overflow: hidden; }
	.share .share-label,
	.share .share-button,
	.share .share-link { float: left; margin-right: 1em; line-height: 1.8em; color: #999; }
	.share .share-link a { 
		display: block; 
		border-radius: 50%;
		font-size: 1em;
		color: #fff;
		padding: 0.4em;
		background: #ababab;
	}
	.share .share-link a .icon {
		display: block;
		width: 1em;
	}
	
	.social-links { overflow: hidden; display: inline-block; text-align: center; }
	.social-link { display: inline-block; margin: 0 0.5em; }
	.social-link:first-child { margin-left: 0; }
	.social-link:last-child { margin-right: 0; }
	.social-link a { 
		display: block; 
		margin: 0;
		border: 2px solid #fff;
		border-radius: 50%; /* CSS3 */
		font-size: 1.2em;
		color: #fff;
		padding: 0.4em;
		height: 2em;
		width: 2em;
	}
	.social-link a .icon {
		display: block;
		width: 1em;
		filter: drop-shadow(-4px 4px 1px rgba(0,0,0,0.3))
	}
	
	.social-link.feed a:focus,
	.social-link.feed a:hover,
	.social-link.feed a:active { background-color: #f26522 !important; }
	.social-link.contact a:focus,
	.social-link.contact a:hover,
	.social-link.contact a:active { background-color: $color-pyrotek-red-dark !important; }
	.social-link.newsletter a:focus,
	.social-link.newsletter a:hover,
	.social-link.newsletter a:active { background-color: #9ac72f !important; }
	.social-link.gift a:focus,
	.social-link.gift a:hover,
	.social-link.gift a:active { background-color: #9ac72f !important; }
	.social-link.facebook a:focus,
	.social-link.facebook a:hover,
	.social-link.facebook a:active,
	.share-link.facebook a:focus,
	.share-link.facebook a:hover,
	.share-link.facebook a:active { background-color: #3B5999 !important; }
	.social-link.twitter a:focus,
	.social-link.twitter a:hover,
	.social-link.twitter a:active,
	.share-link.twitter a:focus,
	.share-link.twitter a:hover,
	.share-link.twitter a:active { background-color: #55acee !important; }
	.social-link.google a:focus,
	.social-link.google a:hover,
	.social-link.google a:active,
	.share-link.google a:focus,
	.share-link.google a:hover,
	.share-link.google a:active { background-color: #dd4b39 !important; }
	.social-link.linkedin a:focus,
	.social-link.linkedin a:hover,
	.social-link.linkedin a:active,
	.share-link.linkedin a:focus,
	.share-link.linkedin a:hover,
	.share-link.linkedin a:active { background-color: #0274b3 !important; }
	.social-link.pinterest a:focus,
	.social-link.pinterest a:hover,
	.social-link.pinterest a:active,
	.share-link.pinterest a:focus,
	.share-link.pinterest a:hover,
	.share-link.pinterest a:active { background-color: #CB2027 !important; }
	.social-link.youtube a:focus,
	.social-link.youtube a:hover,
	.social-link.youtube a:active,
	.share-link.youtube a:focus,
	.share-link.youtube a:hover,
	.share-link.youtube a:active { background-color: #C7231A !important; }
	
}