@media print
{
	body { font-size:10pt; color: #000; }

	header, #main, .layout-band, .block_area {
		min-width: 19em; /* 304px */
	}
	#mainnav, #subnav, #keyvisual, #richfooter, footer,	.content-footer { 
		display:none; 
	}
	
	#Form_CommentsForm, .commenting-rss-feed, #comments-holder h4:first-child { display:none; }
	
	.mobile-only { display: none; }

	h1,h2,h3,h4,h5,h6 { page-break-after:avoid; }
	h1 { margin: 0; } 
	
	/* unfix header */
	header .wrapper { margin: 0; display: block; }
	#logo { display: block; }
  
	.ym-col3 { display: none !important; margin: 0 !important; }
	.ym-col1 { clear: both; float: none; width: 100% !important; }
	
	.backlink, .morelink { display: none; }
	
	a { text-decoration:underline; padding-left: 0 !important; background: none !important; }
	a[href]:after {
		content:" <URL: "attr(href)">";
		color:#444;
		font-size: 0.8em;
		background: transparent;
		font-style: italic;
		font-weight: normal;
		font-family: $font-stack-body;
	}
	a[class~="image"]:after,
	#logo:after {
		content:"";
	}
	
	#main { margin-top: 2em; padding: 0; }

}
